import api from "../api";

export async function getWallet() {
  const response = await api.get(`/wallet`);
  return response.data;
}

export async function createOrAddWalletCard(payload: any) {
  const response = await api.post(`/wallet`, payload);
  return response.data;
}

export async function renewSub(subscriptionId: string, installments: number) {
  const response = await api.post("/subs/renew", {
    subscriptionId,
    installments,
  });
  return response.data;
}
