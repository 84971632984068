import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  List,
  Modal,
  Tabs,
  Divider,
  message,
  Form,
  Input,
  Switch,
  Select,
} from "antd";
import {
  CheckOutlined,
  ReloadOutlined,
  PlusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import styles from "./walletStyles.module.scss";
import {
  createOrAddWalletCard,
  getWallet,
  renewSub,
} from "../../services/requests/wallet";
import moment from "moment";

// Exemplo de dados mock para cartões e assinaturas
interface Cartao {
  id: number;
  lastFourDigits: string;
  brand: string;
  isDefault: boolean;
  cardHolderName: string;
  expirationDate: string;
}

interface Assinatura {
  _id: string;
  planId: string;
  nome: string;
  status: "active" | "canceled";
  endDate: string;
}

const WalletComponent: React.FC = () => {
  const [cartoes, setCartoes] = useState<Cartao[]>([]);
  const [assinaturas, setAssinaturas] = useState<Assinatura[]>([]);
  const [defaultCardId, setDefaultCardId] = useState<number | null>(null);
  const [modalRenovacao, setModalRenovacao] = useState<boolean>(false);
  const [assinaturaSelecionada, setAssinaturaSelecionada] =
    useState<Assinatura | null>(null);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [installments, setInstances] = useState(1);
  const [form] = Form.useForm();

  const _fetch = async () => {
    try {
      const response = await getWallet();
      console.log(response);

      if (response?.cards) {
        setCartoes(response.cards);
      }

      if (response?.subscriptions) {
        const subs = response.subscriptions;

        setAssinaturas(subs);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Simula a busca de dados (em uma aplicação real, faça chamadas à API)
  useEffect(() => {
    _fetch();
  }, []);

  // Função para definir um cartão como padrão
  const handleSetDefault = (id: number) => {
    setDefaultCardId(id);
    setCartoes((prev) => prev.map((c) => ({ ...c, isDefault: c.id === id })));
    message.success("Cartão definido como principal!");
  };

  // Abre modal de renovação de assinatura
  const handleRenewSubscription = (assinatura: Assinatura) => {
    setAssinaturaSelecionada(assinatura);
    setModalRenovacao(true);
  };

  // Confirma a renovação (aqui você chamaria sua API)
  const confirmRenewal = async () => {
    try {
      if (!assinaturaSelecionada) return;
      await renewSub(assinaturaSelecionada?._id, installments);
      message.success(
        `Assinatura ${assinaturaSelecionada?.nome} renovada com sucesso!`
      );
      setModalRenovacao(false);
      await _fetch();
    } catch (error: any) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
    // Atualize o status da assinatura conforme necessário
  };

  // Abre modal de cadastro de novo cartão
  const openRegisterModal = () => {
    setLoading(true);
    setIsRegisterModalVisible(true);
  };

  // Lida com o cadastro do novo cartão
  const handleRegisterCard = async (values: any) => {
    try {
      const payload = {
        expirationDate: values.data,
        brand: values.bandeira,
        cardHolderName: values.nome,
        cardType: "credit",
        country: "BR",
        lastFourDigits: values.numero.slice(-4),
        cardNumber: values.numero,
        cvv: values.cvv,
      };
      await createOrAddWalletCard(payload);
      message.success("Cartão cadastrado com sucesso!");
      setIsRegisterModalVisible(false);
      form.resetFields();
      await _fetch();
    } catch (error) {
      message.error("Erro ao cadastrar cartão!" + error);
    }
  };

  const handleCancelRegister = () => {
    setIsRegisterModalVisible(false);
    form.resetFields();
  };

  return (
    <div className={styles["wallet-container"]}>
      <div className={styles["cards-section"]}>
        <div className={styles["cards-header"]}>
          <h3>Cartões</h3>
          <Button
            style={{ marginBottom: 30 }}
            icon={<PlusCircleOutlined />}
            block
            onClick={openRegisterModal}
          >
            Cadastrar Cartão
          </Button>
        </div>
        <List
          grid={{ gutter: 16, column: 2 }}
          dataSource={cartoes}
          renderItem={(cartao) => (
            <List.Item key={cartao.id}>
              <Card
                title={cartao.cardHolderName}
                size="small"
                extra={
                  cartao.isDefault && (
                    <CheckOutlined style={{ color: "green" }} />
                  )
                }
              >
                <p>**** **** **** {cartao.lastFourDigits}</p>
                <p>{cartao.brand}</p>
                <p>{cartao.expirationDate}</p>

                {!cartao.isDefault ? (
                  <Button
                    type="primary"
                    size="small"
                    block
                    onClick={() => handleSetDefault(cartao.id)}
                  >
                    Principal
                  </Button>
                ) : (
                  <div style={{ minHeight: 35 }}></div>
                )}
              </Card>
            </List.Item>
          )}
        />
      </div>

      <Divider />

      {/* Seção de Assinaturas */}
      <div className={styles["subscriptions-section"]}>
        <h3>Assinaturas</h3>
        <Tabs defaultActiveKey="active">
          <Tabs.TabPane tab="Ativas" key="active">
            <List
              dataSource={assinaturas.filter((a) => a.status === "active")}
              renderItem={(assinatura) => (
                <List.Item key={assinatura._id} style={{ marginBottom: 30 }}>
                  Acesso ao PlanoNPAC – Válida até{" "}
                  {moment(assinatura.endDate).format("LL")}
                </List.Item>
              )}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Inativas" key="inactive">
            <List
              dataSource={assinaturas.filter((a) => a.status === "canceled")}
              renderItem={(assinatura) => (
                <List.Item key={assinatura._id}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <small style={{ marginRight: 5 }}>
                      Acesso ao PlanoNPAC – Expirada em
                    </small>
                    <small style={{ marginRight: 10 }}>
                      {moment(assinatura.endDate).format("DD/MM/YYYY")}
                    </small>

                    <ReloadOutlined
                      size={10}
                      style={{ color: "#1890ff" }}
                      onClick={() => handleRenewSubscription(assinatura)}
                    />
                  </div>
                </List.Item>
              )}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>

      {/* Modal para renovação de assinatura */}
      <Modal
        title="Renovar Assinatura"
        visible={modalRenovacao}
        onOk={confirmRenewal}
        onCancel={() => setModalRenovacao(false)}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <p>
          Deseja renovar a assinatura{" "}
          <strong>{assinaturaSelecionada?.nome}</strong>?
        </p>
        <Select
          defaultValue="Parcelar assinatura?"
          style={{ width: 190 }}
          onChange={(value) => setInstances(parseInt(value))}
          options={Array.from({ length: 12 }, (_, index) => ({
            value: index + 1,
            label: `${index + 1}`,
          }))}
        />
      </Modal>

      {/* Modal para cadastro de novo cartão */}
      <Modal
        title="Cadastrar Cartão"
        visible={isRegisterModalVisible}
        onCancel={handleCancelRegister}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleRegisterCard}>
          <Form.Item
            label="Nome do Cartão"
            name="nome"
            rules={[{ required: true, message: "Informe o nome do cartão!" }]}
          >
            <Input placeholder="*José da Silva" />
          </Form.Item>
          <Form.Item
            label="Número do Cartão"
            name="numero"
            rules={[{ required: true, message: "Informe o número do cartão!" }]}
            normalize={(value: string) => {
              if (!value) return "";
              // Remove caracteres não numéricos e insere espaço a cada 4 dígitos.
              const digits = value.replace(/\D/g, "");
              return digits.match(/.{1,4}/g)?.join(" ") || "";
            }}
          >
            <Input placeholder="1234 5678 9012 3456" maxLength={19} />
          </Form.Item>
          <Form.Item
            label="Bandeira"
            name="bandeira"
            rules={[
              { required: true, message: "Informe a bandeira do cartão!" },
            ]}
          >
            <Input placeholder="Visa, MasterCard, etc." />
          </Form.Item>
          <Form.Item
            label="Data de validade"
            name="data"
            rules={[
              {
                required: true,
                message: "Informe a data de validade do cartão!",
              },
              {
                pattern: /^(0[1-9]|1[0-2])\/\d{4}$/,
                message: "Formato inválido. Use MM/AAAA",
              },
            ]}
            normalize={(value: string) => {
              if (!value) return "";
              // Remove tudo que não for dígito e insere "/" após os 2 primeiros dígitos.
              const digits = value.replace(/\D/g, "");
              if (digits.length < 3) return digits;
              const month = digits.slice(0, 2);
              const year = digits.slice(2, 6);
              return month + (year ? "/" + year : "");
            }}
          >
            <Input placeholder="03/2025" maxLength={7} />
          </Form.Item>

          <Form.Item
            label="CVV"
            name="cvv"
            rules={[
              {
                required: true,
                message: "Informe a cvv de validade do cartão!",
              },
            ]}
          >
            <Input placeholder="333" maxLength={3} />
          </Form.Item>
          <Form.Item
            label="Cartão principal?"
            name="isDefault"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Cadastrar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default WalletComponent;
