import {
  Bell,
  LogOut,
  Menu,
  Settings,
  ShoppingCart,
  UserPlus,
  Wallet,
  CopyIcon,
} from "lucide-react";
import styles from "./styleNavbar.module.scss";
import { ContextApi } from "../../../../contexts";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Badge, Dropdown, MenuProps, Modal } from "antd";
import {
  notifications,
  readNotifications,
} from "../../../../services/requests/notification";
import WalletComponent from "../../../Wallet";

export default function Navbar() {
  const { logoutRequest, drawerOpen, setDrawerOpen, user } =
    useContext(ContextApi);
  const [notification, setNotification] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const _fetch = async () => {
    await notifications(user!._id)
      .then((response) => {
        setNotification(response?.data);
      })
      .catch(console.log);
  };
  useEffect(() => {
    _fetch();
  }, []);

  const readNotification = async (id: string) => {
    await readNotifications(id)
      .then(() => {
        _fetch();
      })
      .catch(console.log);
  };

  const navigate = useNavigate();
  const items: MenuProps["items"] = notification.map((notification: any) => {
    return {
      key: notification?._id,
      label: (
        <div
          className={styles.notification}
          onMouseLeave={() => readNotification(notification?._id)}
        >
          <span className={styles.textNotification}>
            {notification?.message ? notification?.message : "-"}
          </span>
          <ShoppingCart className={styles.icon} />
        </div>
      ),
    };
  });
  const link = btoa(user?._id as string);
  const location = window.location.href.split("/");
  const redirectUrlInavtion =
    location[0] + "//" + location[2] + "/invitation/" + link;

  const [copied, setCopied] = useState<boolean>(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(redirectUrlInavtion);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000); // Reseta o estado após 2 segundos
      alert("Link copiado");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleCopyCoupon = async () => {
    try {
      if (!user?.coupon) {
        toast.error("Você não possui um cupom válido");
        return;
      }

      await navigator.clipboard.writeText(user?.coupon);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000); // Reseta o estado após 2 segundos
      alert("Link copiado");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div className={styles.background}>
      <div className={styles.navbar}>
        <div className={styles.leftContainer}>
          <div className={styles.menu}>
            <Menu
              onClick={() => setDrawerOpen(!drawerOpen)}
              className={styles.icon}
            />
          </div>
          <div className={styles.logo} onClick={() => navigate("/")}>
            <h4>
              Plano<span>NPAC</span>
            </h4>
          </div>
        </div>
        <div className={styles.actions}>
          <Link
            to={"https://leonardomarcondes.com.br/destrava11sp/"}
            target="_blank"
            className={styles.orangeButton}
          >
            Destrava
          </Link>
          <Link
            to={
              "https://escola.npac.com.br/auth/login?redirect=/office/minha_cademi/aparencia"
            }
            target="_blank"
            className={styles.orangeButton}
          >
            Escola NPAC
          </Link>

          <small>Cupom do agente: {user?.coupon}</small>
          <CopyIcon
            onClick={handleCopyCoupon}
            className={styles.icon}
            color={copied ? "#00ff00" : "#8B8B8B"}
          />

          <div className={styles.divider} />

          <Wallet className={styles.icon} onClick={() => showModal()} />

          <div className={styles.divider} />

          <UserPlus
            onClick={handleCopy}
            className={styles.icon}
            color={copied ? "#00ff00" : "#8B8B8B"}
          />

          <div className={styles.divider} />
          <Badge count={items?.length} className={styles.icon}>
            <Dropdown menu={{ items }} placement="bottom" arrow>
              <Bell />
            </Dropdown>
          </Badge>
          <div className={styles.divider} />
          <Settings
            className={styles.icon}
            onClick={() => navigate("/agent-profile")}
          />

          <div className={styles.divider} />
          <LogOut onClick={logoutRequest} className={styles.icon} />
        </div>
      </div>
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <WalletComponent />
      </Modal>
    </div>
  );
}
