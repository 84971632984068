import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserById } from "../../services/requests/users";

const RedirectPage: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    const fetchUserAndRedirect = async () => {
      if (!productId) return;

      try {
        // Decodifica o productId para extrair o objeto com userId
        const decodedData = JSON.parse(atob(productId));
        const userId = decodedData?.userId;
        console.log("Decoded userId:", userId);

        if (!userId) {
          console.error("User ID não encontrado no token decodificado.");
          return;
        }

        // Busca as informações do usuário pelo userId
        const response = await getUserById(userId);

        const userInfo = response.data?.user;
        console.log("User info:", userInfo?.coupon);

        if (!userInfo?.coupon) {
          console.error("Coupon não encontrado para o usuário.");
          return;
        }

        // Monta a URL de redirecionamento com base no coupon do usuário
        const url = `https://checkout.planonpac.com.br/${userInfo.coupon}`;
        setRedirectUrl(url);

        // Redireciona automaticamente após a obtenção do usuário
        window.location.href = url;
      } catch (error) {
        console.error("Erro ao obter usuário ou redirecionar:", error);
      }
    };

    fetchUserAndRedirect();
  }, [productId]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <p>Redirecionando...</p>
      {redirectUrl && (
        <button
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            fontSize: "16px",
            cursor: "pointer",
          }}
          onClick={() => (window.location.href = redirectUrl)}
        >
          Clique aqui para redirecionar!
        </button>
      )}
    </div>
  );
};

export default RedirectPage;
